import * as React from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import ProductCardGrid from '../components/ProductCardGrid';
import Title from '../components/Title';
import * as styles from './index.module.css';
import { navigate } from 'gatsby';
import productsData from '../helpers/product.json';


const IndexPage = () => {
  const newArrivals = productsData;

  const goToShop = () => {
    document.getElementById('products-list').scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <Layout disablePaddingBottom>
      {/* Hero Container */}
      <Hero
        maxWidth={'500px'}
        image={'/pexels-jakub-zerdzicki-19746343.jpg'}
        title={'Quality 3D Products'}
        ctaText={'shop now'}
        ctaAction={goToShop}
      />

      {/* Message Container */}
      {/* <div className={styles.messageContainer}>
        <p>
          This is a demonstration of the Sydney theme for verse by{' '}
          <span className={styles.gold}>matter design.</span>
        </p>
        <p>
          wear by <span className={styles.gold}>sunspel</span> and{' '}
          <span className={styles.gold}>scotch&soda</span>
        </p>
      </div> */}



      {/* Products */}
      <div className={styles.newArrivalsContainer} id="products-list">
        <Container>
          <Title name={'Products'} />
          <ProductCardGrid
            spacing={true}
            showSlider
            height={480}
            columns={3}
            data={newArrivals}
          />
        </Container>
      </div>



    </Layout>
  );
};

export default IndexPage;
